const SortAsc = () => {
  return (
    <svg
      width="14px"
      height="14px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" />

      <g id="SVGRepo_tracerCarrier" />

      <g id="SVGRepo_iconCarrier">
        {" "}
        <g id="Edit / Sort_Descending">
          {" "}
          <path
            id="Vector"
            d="M4 17H16M4 12H13M4 7H10M18 13V5M18 5L21 8M18 5L15 8"
            stroke="#ffffff"
          />{" "}
        </g>{" "}
      </g>
    </svg>
  );
};

export default SortAsc;
