/* eslint-disable react/style-prop-object */
import React from "react";
import Header from "./Header";
import "./Privacy.scss";

const headerLeftContent = (
  <>
    <h1>Privacy Policy</h1>
  </>
);

const Privacy = () => {
  return (
    <div>
      <Header leftContent={headerLeftContent} />

      <div className="container-privacy">
      <p>
      <b>Responsible for the processing of data is:</b><br /><br />
      GESKE Beauty Tech Distribution GmbH<br />
      Leipziger Platz 18<br />
      10117 Berlin<br />
      Germany <br />
      <a href="mailto:privacymanager@geske.com">privacymanager@geske.com</a>
      </p>
      
      <p>
      Thank you for visiting our online shop. Protection of your privacy is very important to us. Below you will find extensive information about how we handle your data.
      </p>

      <h2>1. Access data and hosting</h2>
      <p>You may visit our website without revealing any personal information. With every visit on the website, the web server stores automatically only a so-called server log file which contains e.g. the name of the requested file, your IP address, the date and time of the request, the volume of data transferred and the requesting provider (access data), and documents the request. These access data are analysed exclusively for the purpose of ensuring the smooth operation of the website and improving our offer. This serves according to Art. 6 (1) 1 lit. f GDPR the protection of our legitimate interests in the proper presentation of our offer that are overriding in the process of balancing of interests. All access data are deleted no later than one month after the end of your visit on our website.</p>

      <h3>Content Delivery Network</h3>
      <p>For the purpose of a shorter loading time, we use for some offers a so-called Content Delivery Network ("CDN"). This service provides content, e.g. large media files, via regionally distributed servers of external CDN service providers. For this reason, access data will be processed on the servers of these service providers. We engage our service providers on the basis of processing on our behalf. Our service providers are located and/or use servers in countries outside the EU and the EEA. For these countries there is no adequacy decision by the European Commission. Our cooperation is based on standard data protection clauses adopted by the European Commission. If you have any questions about our service providers and the basis of our cooperation with them, please use the contact option described in this privacy policy.</p>

      <h2>2. Data processing for the purposes of processing the contract</h2>
      <h3>2.1 Data processing for the purposes of performing the contract</h3>
      <p>For the purpose of performing the contract (including enquiries regarding the processing of any existing warranty and performance fault claims as well as any statutory updating obligations) in accordance with Art. 6 (1) (b) GDPR, we collect personal data if you provide it to us voluntarily as part of your order. Mandatory fields are marked as such, as in these cases we necessarily need the data to process the contract and we cannot send the order without their specification. Which data is collected can be seen from the respective input forms. Further information on the processing of your data, in particular on the forwarding of the data to our service providers for the purpose of order, payment and shipping, can be found in the following sections of this privacy policy. After complete processing of the contract, your data will be restricted for further processing and deleted after expiry of the retention periods under tax and commercial law in accordance with Art. 6 (1) (c) GDPR, unless you have expressly consented to further use of your data in accordance with Art. 6 (1) (a) GDPR or we reserve the right to use data beyond this, which is permitted by law and about which we inform you in this privacy policy.</p>

      <h2>3. Data processing for the purposes of shipment</h2>
      <p>We forward your data to the shipping company within the scope required for the delivery of the ordered goods according to Art. 6 (1) (b) GDPR.</p>

      <h2>4. Data processing for the purposes of payment</h2>
      <p>As part of the payment process in our online shop, we work together with these partners: technical service provider, credit institution, payment service provider.</p>

      <h3>4.1 Data processing for the purposes of transaction processing</h3>
      <p>Depending on the selected payment method, we forward the data necessary for processing the payment transaction to our technical service providers, who act for us on the basis of processing on our behalf or to the authorised credit institutions or to the selected payment service provider insofar as this is necessary for the payment process. This serves the fulfilment of the contract according to Art. 6 (1) (b) GDPR. In certain cases, payment service providers collect the data required for processing the payment themselves, e.g. on their own website or via technical solution within the ordering process. In this respect, the privacy policy of the respective payment service provider applies. If you have any questions about our payment processing partners
      and the basis of our cooperation with them, please use the contact option described in this privacy policy.</p>

      <h3>4.2 Data processing for the purposes of fraud prevention and optimisation of our payment processes</h3>
      <p>We may forward other data to our service providers, which they use for the purpose of fraud prevention and to optimise our payment processes (e.g. invoicing, processing of contested payments, accounting support) together with the data necessary to process the payment as our processors.
      This serves to safeguard our legitimate interests in fraud prevention or an efficient payment management in accordance with Art. 6 (1) (f) GDPR that are overriding in the process of balancing of interests.</p>

      <h2>5. Cookies and further technologies</h2>

      <h3>General information</h3>
      <p>In order to make visiting our website attractive and to enable the use of certain functions, to display suitable products or for market research, we use technologies on various pages, including so-called cookies. Cookies are small text files that are automatically stored on your end device. Some of the cookies we use are deleted after the end of the browser session, i.e. after closing your browser (so-called session cookies). Other cookies remain on your end device and enable us to recognise your browser during your next visit (persistent cookies).</p>

      <h3>Protection of privacy for terminal devices</h3>
      <p>When you use our online services, we use technologies that are absolutely necessary
      in order to provide the telemedia service you have expressly requested. The storage of information in your terminal device or access to information that is already stored in your terminal device does not require consent in this respect.</p>

      <h3>Any downstream data processing through cookies and other technologies</h3>
      <p>We use such technologies that are strictly necessary for the use of certain functions of our website (e.g. shopping cart function). These technologies are used to collect and process IP addresses, time of visit, device and browser information as well as information on your use of our website (e.g. information on your preferences). This serves to safeguard our legitimate interests in an optimised presentation of our offer that are overriding in the process of balancing of interests.</p>

      <p>You can find the cookies settings for your browser by clicking on the following links: <a href="https://support.microsoft.com/de-de/help/4027947/microsoft-edge-delete-cookies" target="_blank" rel="noreferrer">Microsoft Edge™</a> / <a href="https://support.apple.com/de-de/guide/safari/sfri11471/12.0/mac/10.14" target="_blank" rel="noreferrer">Safari™</a> / <a href="https://support.google.com/chrome/answer/95647?hl=de&hlrm=en" target="_blank" rel="noreferrer">Chrome™</a> / <a href="https://support.mozilla.org/de/products/firefox/protect-your-privacy/cookies" target="_blank" rel="noreferrer">Firefox™</a> / <a href="https://help.opera.com/de/latest/web-preferences/#cookies" target="_blank" rel="noreferrer">Opera™</a></p>

      <p>How can I configure the cookie settings of my browser?</p>
      Each browser is different in the way it manages cookie settings. This is described in the help menu of each browser, which explains how to change your cookie settings. You can find these for each browser under the following links: <a href="https://support.microsoft.com/en-gb/help/4027947/microsoft-edge-delete-cookies" target="_blank" rel="noreferrer">Microsoft Edge™</a> / <a href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac" target="_blank" rel="noreferrer">Safari™</a> / <a href="https://support.google.com/chrome/answer/95647?hl=en&hlrm=en" target="_blank" rel="noreferrer">Chrome™</a> / <a href="https://support.mozilla.org/en-US/products/firefox/protect-your-privacy/cookies" target="_blank" rel="noreferrer">Firefox™</a> / <a href="https://help.opera.com/en/latest/web-preferences/#cookies" target="_blank" rel="noreferrer">Opera™</a>

      <p>What types of cookies are being used?</p>
      <p><b>Essential cookies:</b> These cookies are necessary to enable you to use our website.
      This includes e.g. cookies that enable you to log into the customer area or add items to your shopping cart.</p>

      <h2>6. Contact options and your rights</h2>

      <h3>6.1 Your rights</h3>
      <p>
        Being the data subject, you have the following rights according to:
        <ul>
        <li>art. 15 GDPR, the right to obtain information about your personal data which we process, within the scope described therein;</li>
        <li>art. 16 GDPR, the right to immediately demand rectification of incorrect or completion of your personal data stored by us;</li>
        <li>art. 17 GDPR, the right to request erasure of your personal data stored with us, unless further processing is required
        to exercise the right of freedom of expression and information;
        for compliance with a legal obligation;
        for reasons of public interest or
        for establishing, exercising or defending legal claims;</li>
        <li>art. 18 GDPR, the right to request restriction of processing of your personal data, insofar as
        the accuracy of the data is contested by you;
        the processing is unlawful, but you refuse their erasure;
        we no longer need the data, but you need it to establish, exercise or defend legal claims, or
        you have lodged an objection to the processing in accordance with art. 21
        GDPR;</li>
        <li>art. 20 GDPR, the right to receive your personal data that you have provided to us in a structured, commonly used and machine-readable format or to request its transmission to another controller;</li>
        <li>art. 77 GDPR, the right to complain to a supervisory authority . As a rule, you can contact the supervisory authority at your habitual place of residence or workplace or at our company headquarters.</li>
        </ul>
      </p>

      <p><b>Right to object</b></p>
      <p>If we process personal data as described above to protect our legitimate interests that are overriding in the process of balancing of interests, you may object to such data processing with future effect. If your data are processed for direct marketing purposes, you may exercise this right at any time as described above. If your data are processed for other purposes, you have the right to object only on grounds relating to your particular situation.</p>
      <p>After you have exercised your right to object, we will no longer process your personal data for such purposes unless we can demonstrate compelling legitimate grounds for the processing which override your interests, rights and freedoms or for the establishment, exercise or defence of legal claims.</p>
      <p>This does not apply to the processing of personal data for direct marketing purposes. In such a case we will no longer process your personal data for such purposes.</p>


      <h3>6.2 Contact options</h3>
      <p>If you have any questions about how we collect, process or use your personal data, want to enquire about, correct, restrict or delete your data, or withdraw any consents you have given, or opt-out of any particular data use, please contact us directly using the contact data provided in our supplier identification.</p>

      <p>
      <b>Data protection Officer:</b><br />
      Trusted Shops AG<br />
      Subbelrather Str. 15c<br />
      50823 Köln<br />
      Germany<br />
      Tel: +49 221 77536 5151<br />
      <a href="mailto:privacymanager@geske.com">privacymanager@geske.com</a>
      </p>
      </div>

</div>
  );
};

export default Privacy;
