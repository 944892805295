import { S3_IMG_BASE_URL } from "../../../constants/s3";

const ImageComponent = function ImgComponent({
  thumbnail,
}: {
  thumbnail: string;
}) {
  return (
    <img
      className="thumbnail"
      src={S3_IMG_BASE_URL + thumbnail}
      alt="Product Thumbnail"
    />
  );
};

export default ImageComponent;
