import "./SubmitOrder.scss";
import { Dialog, DialogTitle, DialogContentText, DialogContent, Button, Box, Divider, TextField } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';

const SubmitOrder = ({
  onClose,
  open,
  onSubmit,
  loading =true
}: {
  onClose: () => void;
  open: boolean;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  loading?: boolean;
}) => {
  return (
    <Dialog
      PaperProps={{
        component: "form",
        onSubmit,
      }}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      open={open}>
      <DialogTitle className="submitOrder__title">Submit order</DialogTitle>
      <Divider />
      <DialogContent className="submitOrder__wrapper">
        <DialogContentText className="submitOrder__text">
          Do you want to add more details for this order?
        </DialogContentText>
        <TextField
          className="submitOrder__input"
          label="Your Order Reference"
          variant="outlined"
          id="customerOrderReference"
          name="customerOrderReference"
        />
        <TextField
          className="submitOrder__input"
          label="Notes or special requests for your order"
          variant="outlined"
          multiline
          rows={8}
          id="notes"
          name="notes"
        />
        <Box component="section" className="submitOrder__buttons">
          <Button endIcon={loading && <CircularProgress size={20} color="inherit" />} disabled={loading} className="submitOrder__buttons__btn submitOrder__submit" variant="contained" color="primary" type="submit">
            Submit
          </Button>
          <Button disabled={loading} className="submitOrder__buttons__btn submitOrder__cancel" variant="text" color="inherit" onClick={onClose}>
            Cancel
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SubmitOrder;
