/* eslint-disable react/style-prop-object */
import React from "react";
import Header from "./Header";
import "./Contact.scss";

const headerLeftContent = (
  <>
    <h1>Contact</h1>
  </>
);

const Contact = () => {
  return (
    <div className="">
      <Header leftContent={headerLeftContent} />

      <div className="container-contact">
      <p>
      Thank you for your excitement and expressed interest in GESKE German Beauty Tech – the only holistic beauty tech range!<br />
      <br />
      We are committed to being a leader in discovering innovative ways to connect with our beauty partners and consumers around the world, delivering effective solutions at affordable prices with speed and simplicity.<br />
      <br />

      <b>Contact us at any time: <a href="mailto:sales@geske.com">sales@geske.com</a></b>
      <br />
      <br />
      GESKE Beauty Tech Distribution GmbH<br />
      Leipziger Platz 18<br />
      10117 Berlin<br />
      Germany <br />
      </p>
      </div>

    </div>
  );
};

export default Contact;
