/* eslint-disable react/style-prop-object */
import React from "react";

const RouteNotFound = () => {
  return (
    <div className="">
        <h1>404</h1>
        Page Not Found
    </div>
  );
};

export default RouteNotFound;
