const ColorColumn = function ColorColumn({
  color,
  colorName,
}: {
  color: string;
  colorName: string;
}) {
  return (
    <>
      {color && <td className="variant_color" style={{ backgroundColor: color }}></td>}
      <td className="variant_name" colSpan={!color ? 2 : undefined}>{colorName}</td>
    </>
  );
};

export default ColorColumn;
