import { formatPrice } from "../../../utils/formatPrice";

const PriceColumn = function PriceColumn({
  price,
  currency,
}: {
  price: number;
  currency: string;
}) {
  return (
    <span className="price">
      {formatPrice(price)} {currency}
    </span>
  );
};

export default PriceColumn;
