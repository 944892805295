/* eslint-disable react/style-prop-object */
import React from "react";

const Header = ({ leftContent }: { leftContent?: React.ReactNode }) => {
  return (
    <div className="headline">
      <div className="headline__text">{leftContent}</div>

      <div className="headline__logo-wrap">
        <img src="/static/img/geske-logo-black.svg" alt="Geske Logo" />
      </div>
    </div>
  );
};

export default Header;
