const SortDesc = () => {
  return (
    <svg
      width="14px"
      height="14px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" />

      <g id="SVGRepo_tracerCarrier" />

      <g id="SVGRepo_iconCarrier">
        {" "}
        <g id="Edit / Sort_Ascending">
          {" "}
          <path
            id="Vector"
            d="M4 17H10M4 12H13M18 11V19M18 19L21 16M18 19L15 16M4 7H16"
            stroke="#ffffff"
          />{" "}
        </g>{" "}
      </g>
    </svg>
  );
};

export default SortDesc;
