import { Link } from "react-router-dom";
import { TableDataType } from "../../types/order-sheet";
import { formatPrice } from "../../utils/formatPrice";
import { formatDiscount } from "../../utils/formatDiscount";
import "./Footer.scss";
import { CONVERSION_FREE_CURRENCIES } from "../../constants/s3";
import { SAMPLE_UNIT_PRICE } from "../../constants";

const Footer = ({
                  orderSheetTableData,
                  currency,
                  exchangeRateFactor,
                  baseCurrency,
                  discount,
                  productPricesIncludeDiscount
                }: {
  orderSheetTableData: TableDataType,
  currency: string,
  exchangeRateFactor: number,
  baseCurrency: string,
  discount: number | null,
  productPricesIncludeDiscount: boolean
}) => {
  const totalWithoutDiscount = orderSheetTableData.data.reduce((sum, product) => sum + product.calculatedPriceWithoutDiscountApplied, 0)
  const currrency : string = CONVERSION_FREE_CURRENCIES.includes(currency) ? currency : baseCurrency
  // legacy behaviour support, can be removed as soon as old ordersheets time out
  if (productPricesIncludeDiscount) {
    return (
        <footer className="footer">
          <div className="footer-container">
            <div className="footer__price">
              <table>
                <tbody>
                <tr className="footer__price__total">
                  <td>Total:</td>
                  <td className="footer__total_amt">{`${formatPrice(totalWithoutDiscount)}`}</td>
                  <td className="footer__total_currency">{currrency}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div className="footer__left">
              <div className="footer__notes">
                {currency === "EUR"
                    ? "¹ Prices will be revised regularly, and customs duties and transportation charges (CIP/DDP) are already factored in."
                    : "¹ Payment must be made in US dollars using the exchange rate on March 12, 2023. Prices will be revised regularly, and customs duties and transportation charges (CIP/DDP) are already factored in."}
              </div>
              <div className="footer__notes">
                {`² Each sample is charged with a token fee of ${SAMPLE_UNIT_PRICE} ${CONVERSION_FREE_CURRENCIES.includes(currency) ? currency : baseCurrency}. We will refund this fee on your next order.`}
              </div>
              {discount &&
                  <div className="footer__notes">
                    {`³ Discount does not apply to samples.`}
                  </div>
              }
              <div className="footer__links">
                <Link to="/o/contact">Contact</Link> –{" "}
                <Link to="/o/privacy">Privacy</Link>
              </div>
            </div>
          </div>
        </footer>
    );
  }

  const totalWithDiscount = orderSheetTableData.data.reduce((sum, product) => sum + (product.calculatedPriceWithPotentialDiscountApplied ?? product.calculatedPriceWithoutDiscountApplied), 0)

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer__price">
          <table>
            <tbody>
            <tr className="footer__price__total">
              <td>Total:</td>
              <td className="footer__total_amt">{`${formatPrice(totalWithDiscount)}`}</td>
              <td className="footer__total_currency">{currrency}</td>
            </tr>
            {discount &&
                <>
                  <tr className="footer__price__discount">
                    <td>Included Discount³:</td>
                    <td className="footer__discount_amt">{`${formatPrice(totalWithoutDiscount - totalWithDiscount)}`}</td>
                    <td className="footer__discount_currency">{currrency}</td>
                  </tr>
                </>
            }
            </tbody>
          </table>
        </div>
        <div className="footer__left">
          <div className="footer__notes">
            {currency === "EUR"
                ? "¹ Prices will be revised regularly, and customs duties and transportation charges (CIP/DDP) are already factored in."
                : "¹ Payment must be made in US dollars using the exchange rate on March 12, 2023. Prices will be revised regularly, and customs duties and transportation charges (CIP/DDP) are already factored in."}
          </div>
          <div className="footer__notes">
            {`² Each sample is charged with a token fee of ${SAMPLE_UNIT_PRICE} ${CONVERSION_FREE_CURRENCIES.includes(currency) ? currency : baseCurrency}. We will refund this fee on your next order.`}
          </div>
          {discount &&
              <div className="footer__notes">
                {`³ Discount does not apply to samples.`}
              </div>
          }
          <div className="footer__links">
            <Link to="/o/contact">Contact</Link> –{" "}
            <Link to="/o/privacy">Privacy</Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
